import config from 'config'
import { useEffect } from 'react'
import superagent from 'superagent'
const NotLogged = () => {

    useEffect(() => {
        let stopRedirect = window.localStorage.getItem('stopRedirect') || false
        if (!!stopRedirect === true) debugger

        // deloguer le coté serveur (jwt blacklist, bdd, cookie etc)
        const logout = async () => {
            const oauthLogoutUrl = config.oauth.logout.url

            superagent
                .get(oauthLogoutUrl)
                .set('Content-Type', 'application/json')
                .set('Accept', 'application/json')
                .set('X-JWT', window.localStorage.getItem('JWT'))
                .withCredentials()

            try {
                function removeAllCookies() {
                    let cookies = document.cookie.split("; ");
                    for (let cookieIndex = 0; cookieIndex < cookies.length; cookieIndex++) {
                        let hostNameSplitted = window.location.hostname.split(".");
                        while (hostNameSplitted.length > 0) {
                            let cookieBase = encodeURIComponent(cookies[cookieIndex].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + hostNameSplitted.join('.') + ' ;path=';
                            let pathNameSplitted = document.location.pathname.split('/');
                            document.cookie = cookieBase + '/';
                            while (pathNameSplitted.length > 0) {
                                document.cookie = cookieBase + pathNameSplitted.join('/');
                                pathNameSplitted.pop();
                            };
                            hostNameSplitted.shift();
                        }
                    }
                }
                removeAllCookies();

            } catch (e) { }

        }
        logout()

        // window.sessionStorage.clear()
        // window.localStorage.clear()
        window.localStorage.removeItem("JWT");
        window.sessionStorage.clear()
        if(process.env.REACT_APP_RIBBON_MESSAGE === 'dev')  window.location.href = `https://front.${config.mainDomainName}`
        else if(process.env.REACT_APP_RIBBON_MESSAGE === 'V2 DEMO')  window.location.href = `https://front.${config.mainDomainName}`
         else window.location.href = `https://${config.mainDomainName}`
    }, [])
    return null
}
export default NotLogged
